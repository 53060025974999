// eslint-disable-next-line no-redeclare,no-unused-vars
function checkSettings(types, settings, objectSub) {
  if (!isArray(types)) types = [types];
  for (var i = 1; i < 6; i++) {
    var no = i;
    if (no == 1) no = '';
    var type = settings['field' + no];
    if (objectSub && settings['field' + no + ucfirst(objectSub)])
      type = settings['field' + no + ucfirst(objectSub)];
    if (inArray(type, types)) return true;
  }
}
